import { UNIQUE_PRICES_HISTORY_LABEL_KEYS } from 'fukku/Price/components/ExtraInfo/Content/keys'

export const PRICE_KEYS = [
	'accessibility.prices.currentPrice.variable',
	'accessibility.prices.firstStrikethroughPrice.variable',
	'accessibility.prices.secondStrikethroughPrice.variable',
	'accessibility.prices.thirdStrikethroughPrice.variable',
	'productCard.discountDescription.text',
	'productCard.discountDescription.outlet.text',
	'accessibility.moreInfo.icon',
]

export const OMNIBUS_KEYS = [
	'productCard.priceHistory.title',
	...UNIQUE_PRICES_HISTORY_LABEL_KEYS,
]
