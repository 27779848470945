import type {
	FavoriteProduct,
	FavoriteProductResponse,
} from 'favorite/types/types'

export const addNewFavorite = (
	favorites: FavoriteProductResponse[] | undefined,
	product: FavoriteProduct
): FavoriteProductResponse[] => {
	const date = new Date().toString()

	return favorites
		? [...favorites, { ...product, date }]
		: [{ ...product, date }]
}
