import { Channel } from 'types/masterData'

export const OUTLET_PRICES_HISTORY_LABEL_KEYS = [
	'productCard.firstPrice.shop.title',
	'productCard.firstPrice.outlet.title',
	'productCard.omnibusPrice.title',
	'productCard.currentPrice.title',
]

export const SHOP_PRICES_HISTORY_LABEL_KEYS = [
	'productCard.firstPrice.title',
	'productCard.omnibusPrice.title',
	'productCard.currentPrice.title',
]

export const UNIQUE_PRICES_HISTORY_LABEL_KEYS = Array.from(
	new Set([
		...OUTLET_PRICES_HISTORY_LABEL_KEYS,
		...SHOP_PRICES_HISTORY_LABEL_KEYS,
	])
)

export const PRICES_TOOLTIP_TITLE = 'productCard.priceHistory.title'

const THREE_PRICES = 3
const TWO_PRICES = 2

export function getTooltipPricesKeys(
	channel: Channel,
	pricesHistory: number[]
) {
	if (channel === Channel.Shop) {
		return SHOP_PRICES_HISTORY_LABEL_KEYS
	} else {
		const [mangoPrice, outletPrice, _omnibusPrice, currentPrice] =
			OUTLET_PRICES_HISTORY_LABEL_KEYS
		if (pricesHistory.length === THREE_PRICES) {
			return [mangoPrice, outletPrice, currentPrice]
		}
		if (pricesHistory.length === TWO_PRICES) {
			return [mangoPrice, currentPrice]
		} else {
			return OUTLET_PRICES_HISTORY_LABEL_KEYS
		}
	}
}
