'use client'

import { Channel } from 'types/masterData'
import { cx } from 'utils/cx'

import { PricesHistory } from './PricesHistory'
import { Title } from './Title'

import styles from '../ExtraInfo.module.scss'
import text from 'fukku/styles/texts.module.scss'

export type BodyContentProps = {
	channel: Channel
	pricesHistory: number[]
	getLabel: (key: string, keys?: Record<string, string> | undefined) => string
	shouldShowDiscount: boolean
	title?: string | null
}

export function BodyContent({
	channel,
	pricesHistory,
	getLabel,
	shouldShowDiscount,
	title,
}: BodyContentProps) {
	const priceDescriptionLabel =
		channel === Channel.Shop
			? 'productCard.discountDescription.text'
			: 'productCard.discountDescription.outlet.text'

	return (
		<div className={styles.bodyContent}>
			{title && <Title title={title} />}
			<PricesHistory
				pricesHistory={pricesHistory}
				getLabel={getLabel}
				channel={channel}
			/>

			{shouldShowDiscount && (
				<p className={cx(text.bodyS, styles.pricesDescription)}>
					{getLabel(priceDescriptionLabel)}
				</p>
			)}
		</div>
	)
}
