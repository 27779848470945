import { useEECEvents } from 'analytics/hooks/useEECEvents/useEECEvents'
import { useDispatchFavorites } from 'favorite/context/FavoritesProvider'
import type { FavoriteProduct } from 'favorite/types/types'
import { useLabels } from 'labels/hooks/useLabels/useLabels'
import { useMasterData } from 'master-data/hooks/useMasterData/useMasterData'
import { useSession } from 'session/src/hooks/useSession'

import { useShowFavorites } from '../utils/showFavorites/client/useShowFavorites'
import { useCheckFavoriteProduct } from './useCheckFavoriteProduct'
import { useNotifications } from './useNotifications'
import { addProduct } from './utils/addProduct'
import { removeProduct } from './utils/removeProduct'

export interface UseFavoriteProps {
	productId: string
	selectedColorId: string
	notification: boolean
	onClickCallback?: () => void
	location: string
}

interface UseFavoriteReturn {
	/** Click handler for favorites button */
	handleClick: () => Promise<void>
	/** Action text of the button */
	buttonLabel: string
	/** true when the product is already in favorites list */
	isFavorite: boolean
	/** Keeper for the Favorites FF */
	showButton: boolean
}

export const useFavorite = ({
	productId,
	selectedColorId,
	notification,
	onClickCallback,
	location,
}: UseFavoriteProps): UseFavoriteReturn => {
	const { t } = useLabels()
	const enabled = useShowFavorites()
	const { sendAddToWishlist, sendRemoveFromWishlist } = useEECEvents()
	const favoritesDispatch = useDispatchFavorites()
	const {
		country: { countryISO, languageISO },
	} = useMasterData()
	const { logged, token } = useSession()
	const product: FavoriteProduct = {
		ref: productId,
		color: selectedColorId,
	}
	const isFavorite = useCheckFavoriteProduct(product)
	const { hasReachedLimit, notifyAndRemoveFavorite, notifyLimit } =
		useNotifications(notification)

	const add = async (): Promise<void> => {
		sendAddToWishlist({
			item: {
				colorId: product.color,
				productId: product.ref,
				quantity: 1,
			},
			location,
		})
		return addProduct({
			logged,
			token,
			product,
			countryISO,
			languageISO,
			favoritesDispatch,
		})
	}

	const remove = async (): Promise<void> => {
		sendRemoveFromWishlist({
			item: {
				colorId: product.color,
				productId: product.ref,
				quantity: 1,
			},
			location,
		})
		removeProduct({
			product,
			favoritesDispatch,
			logged,
			token,
		})

		return notifyAndRemoveFavorite(add)
	}

	const buttonLabel = t(
		`accessibility.wishlist.${isFavorite ? 'remove' : 'add'}.button`
	)

	const handleClick = async () => {
		try {
			if (isFavorite) {
				await remove()
			} else {
				const limitReached = await hasReachedLimit()
				if (limitReached) {
					notifyLimit()
				} else {
					await add()
				}
			}
			onClickCallback?.()
		} catch (e) {
			console.error(`Failed to ${isFavorite ? 'remove' : 'add'} favorite`, e)
		}
	}

	return {
		handleClick,
		buttonLabel,
		isFavorite: !!isFavorite,
		showButton: !!enabled,
	}
}
