import type { ProductType } from 'cache/types/types'
import { FAVORITES_LIST_KEY } from 'favorite/constants/constants'
import { removeFavorite } from 'favorite/services/removeFavorite'
import type { FavoriteProduct, FavoritesState } from 'favorite/types/types'
import { favoritesCache } from 'favorite/utils/favoritesCache'
import { getCacheFavorites } from 'favorite/utils/getCacheFavorites'
import type { Dispatch } from 'react'

import { chooseFavoritesFilter } from './favoritesFilter'

export interface RemoveProductType {
	product: FavoriteProduct
	logged: boolean
	token: string | null
	favoritesDispatch: Dispatch<Partial<FavoritesState>>
}

const failedToRemoveFavorite = () => {
	throw new Error('Failed to remove favorite')
}

export const removeProduct = async ({
	product,
	logged,
	token,
	favoritesDispatch,
}: RemoveProductType): Promise<void> => {
	const favorites = await getCacheFavorites()
	const favorite = favorites?.find(
		({ ref, color }: { ref: string; color: string }) =>
			ref === product.ref && color === product.color
	)
	const id = favorite?.id

	if (!id && logged) {
		failedToRemoveFavorite()
	}

	const favoritesFilter = chooseFavoritesFilter(logged)

	const newFavorites = favorites?.filter((item: ProductType) =>
		favoritesFilter(item, favorite ?? product)
	)

	if (token && id && logged) {
		const response = await removeFavorite({ id, token })
		if (!response.ok) {
			failedToRemoveFavorite()
		}
	}

	await favoritesCache()?.addKeyToCache(FAVORITES_LIST_KEY, newFavorites)
	favoritesDispatch({ favorites: newFavorites || [] })

	return Promise.resolve()
}
